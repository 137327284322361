const isLocalHost = process.env.I18N_HOSTNAME_PRIMARY
	? process.env.I18N_HOSTNAME_PRIMARY.includes('localhost')
	: false;

const DOMAINS = [
	{
		id: 'primary',
		domain: process.env.I18N_HOSTNAME_PRIMARY,
		defaultLocale: 'en-us',
		locales: ['asia', 'emea'],
		http: isLocalHost,
	},
	{
		id: 'ca',
		domain: process.env.I18N_HOSTNAME_CA,
		defaultLocale: 'en-ca',
		locales: ['en-ca', 'fr'],
		http: isLocalHost,
	},
	{
		id: 'es',
		domain: process.env.I18N_HOSTNAME_ES,
		defaultLocale: 'es',
		locales: ['es', 'br', 'en-es'],
		http: isLocalHost,
	},
	{
		id: 'jp',
		domain: process.env.I18N_HOSTNAME_JP,
		defaultLocale: 'ja',
		locales: ['ja'],
		http: isLocalHost,
	},
];

module.exports = { DOMAINS };
