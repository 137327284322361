import { config } from 'utils/style';
import useWindowSize from './useWindowSize';

/**
 * This hook returns a boolean based on the client window size.
 * Example: `useIsMobile('sm')` || `useIsMobile('xl')`.
 *
 * @param {string|number} bp Breakpoint name: `sm|md|lg|1200` || width value w/o unit.
 * @returns {boolean} True when windowWidth < `bp`
 */
export default function useIsMobile(bp = 'md') {
	const windowWidth = useWindowSize().width;
	const breakpoint = typeof bp === 'number' ? bp : config[bp];

	if (typeof breakpoint !== 'number') {
		throw new Error(`useIsMobile: Unable to locate a value for: ${bp}.`);
	}

	return windowWidth < breakpoint;
}
