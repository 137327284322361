import { normalizeUrl } from 'utils/url';
import { addQueryArgs } from '@wordpress/url';

export const baseUrl = normalizeUrl(process.env.NEXT_PUBLIC_WP_API_URL);

export const localeUrl = (path, locale) => {
	return `${baseUrl}${path}`;
};

const getPagination = (headers) => {
	const total = headers.get('X-WP-Total');
	const pages = headers.get('X-WP-TotalPages');

	return {
		total: Number(total),
		pages: Number(pages),
	};
};

export function client(endpoint, { body, ...customConfig } = {}) {
	const headers = new Headers();
	headers.set('Content-Type', 'application/json');
	headers.set('Host', process.env.HOST_HEADER);

	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: { ...headers, ...customConfig.headers },
	};

	if (body) {
		config.body = JSON.stringify(body);
	}

	return fetch(endpoint, config).then(async (response) => {
		const data = await response.json();

		if (response.ok) {
			return {
				data,
				pagination: getPagination(response.headers),
			};
		}
		return Promise.reject(data);
	});
}

export const apiGet = async (url, args = {}, withMinute = false) => {
	const coeff = 1000 * 60;
	const date = new Date();
	const currentMinute = new Date(Math.round(date.getTime() / coeff) * coeff).toISOString();

	const queryArgs = withMinute
		? {
				// Busts cache every minute.
				cacheTime: currentMinute,
		  }
		: {};

	const data = await fetch(addQueryArgs(url, queryArgs), args);

	const receivedHeaders = [...data.headers.entries()].reduce(
		(collection, pair) => ({
			...collection,
			[pair[0]]: pair[1],
		}),
		{},
	);

	const json = await data.json();

	return { headers: receivedHeaders, json };
};
