import * as React from 'react';
import MenuItem from './MenuItem';

/**
 * @todo document this component
 */
class SubMenu extends React.Component {
	menuItems = (menuItems) => {
		return menuItems.map((menuItem) => {
			return (
				<MenuItem
					key={menuItem.ID}
					item={menuItem}
					desktop={this.props.desktop}
					subopen={this.props.subopen}
					id={this.props.id}
					subnavclick={this.props.subnavclick}
					subnavfocus={this.props.subnavfocus}
					menuLinkClickAction={this.props.menuLinkClickAction}
					child
				/>
			);
		});
	};

	render() {
		if (this.props.parentid) {
			const hidden = this.props.subopen !== this.props.parentid;
			const elStyle = {
				maxHeight: this.props.maxHeight,
			};

			return (
				<ul
					id={`submenu-of-${this.props.parentid}`}
					className="sub-menu"
					aria-hidden={hidden}
					style={elStyle}
				>
					<div className="submenu-content">{this.menuItems(this.props.menu)}</div>
				</ul>
			);
		}

		return <ul className="sub-menu">{this.menuItems(this.props.menu)}</ul>;
	}
}

export default SubMenu;
