import React from 'react';
import { getGlobalLinks } from 'components/Global';

export const footerLinkItems = (footerLinkInfo) => {
	return footerLinkInfo.map((footerLinkItem, index) => (
		<li className="sb-footer-nav__li" key={index}>
			<a
				className="sb-footer-nav__link"
				href={footerLinkItem.url}
				target={footerLinkItem.target || '_blank'}
				rel="noreferrer"
			>
				{footerLinkItem.text}
			</a>
		</li>
	));
};

export const legalLinksLinkItems = (legal) => {
	return legal.map((item, index) => (
		<li className="sb-legal-nav__li" key={index}>
			<a
				className="sb-legal-nav__link"
				href={item.url}
				target={item.target || '_blank'}
				rel="noreferrer"
			>
				{item.text}
			</a>
		</li>
	));
};

export const getFooterLinks = () => {
	return {
		general: [
			getGlobalLinks().starbucksMainSite,
			getGlobalLinks().starbucksCareers,
			getGlobalLinks().starbucksReserve,
			getGlobalLinks().starbucksAtHome,
		],
		legal: [getGlobalLinks().privacyPolicy, getGlobalLinks().termsConditions],
	};
};
