const LOCALES = [
	{
		id: 'en-us',
		name: 'English',
		language_label: 'English',
		locale: 'en-us',
		region: 'usa',
		domain: 'primary',
		apiPrefix: '',
		default: true,
	},
	{
		id: 'emea',
		name: 'English',
		language_label: 'English',
		locale: 'en-emea',
		region: 'emea',
		domain: 'primary',
		apiPrefix: 'emea-en',
		default: false,
	},
	{
		id: 'asia',
		name: 'English',
		language_label: 'English',
		locale: 'en-asia',
		region: 'asia',
		domain: 'primary',
		apiPrefix: 'asia',
		default: false,
	},
	{
		id: 'en-ca',
		name: 'English',
		language_label: 'English',
		locale: 'en-ca',
		region: 'canada',
		domain: 'ca',
		apiPrefix: 'ca-en',
		default: true,
	},
	{
		id: 'fr',
		name: 'Français',
		language_label: 'French',
		locale: 'fr-ca',
		region: 'canada',
		domain: 'ca',
		apiPrefix: 'ca-fr',
		default: false,
	},
	{
		id: 'es',
		name: 'Español',
		language_label: 'Spanish',
		locale: 'es',
		region: 'latin-america',
		domain: 'es',
		apiPrefix: 'historias-es',
		default: true,
	},
	{
		id: 'br',
		name: 'Português',
		language_label: 'Portugese',
		locale: 'es-br',
		region: 'latin-america',
		domain: 'es',
		apiPrefix: 'historias-br',
		default: false,
	},
	{
		id: 'en-es',
		name: 'English',
		language_label: 'English',
		locale: 'en-es',
		region: 'latin-america',
		domain: 'es',
		apiPrefix: 'historias-en',
		default: true,
	},
	{
		id: 'ja',
		name: '日本語',
		language_label: 'Japanese',
		locale: 'ja',
		region: 'japan',
		domain: 'jp',
		apiPrefix: 'japan',
		default: true,
	},
];

module.exports = { LOCALES };
