import React from 'react';
import classNames from 'classnames';

const StickyAudio = ({ block }) => {
	if (!block) {
		return null;
	}

	const {
		attributes: { title = null, media: { url = null } = {} },
	} = block;

	const className = classNames('sbx-sticky-header-audio', {
		'sbx-sticky-header-audio--has-title': title,
	});

	return (
		<div className={className}>
			<div className="sbx-sticky-header-audio__inner">
				{title && <p className="sbx-sticky-header-audio__title">{title}</p>}
				<audio
					className="sbx-sticky-header-audio__player"
					controls="controls"
					src={url}
					autoPlay={false}
					loop={false}
					controlsList="nodownload"
				/>
			</div>
		</div>
	);
};
export default StickyAudio;
