import * as React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import useIsSsr from 'hooks/useIsSsr';
import useStickyAudio from 'hooks/useStickyAudio';
import ProgressBar from 'components/ProgressBar';
import DesktopSiteHeader from './Variants/Desktop';
import MobileSiteHeader from './Variants/Mobile';
import StickyAudio from './StickyAudio';

// @TODO Additional refactoring needed. Working to convert class
// components to function components. In doing so, we could remove
// most of the close menu logic and instead use React.useEffect to
// fire a simple close handler when the Next.useRouter().pathname
// changes while navigating client side.
export default function SiteHeader(props) {
	const { menus = {}, menuItems = [], epConfig, desktop, logo, pageProps } = props;

	const isSsr = useIsSsr();
	const { hasStickyAudio, stickyAudioBlock } = useStickyAudio(pageProps);

	const className = classname('sb-site-header', {
		'has-sticky-audio': hasStickyAudio,
	});

	return (
		<header className={className} role="banner">
			{!isSsr ? (
				<>
					{desktop ? (
						<DesktopSiteHeader
							menus={menus}
							menuItems={menuItems}
							logo={logo}
							epConfig={epConfig}
						/>
					) : (
						<MobileSiteHeader
							menus={menus}
							menuItems={menuItems}
							logo={logo}
							epConfig={epConfig}
						/>
					)}
				</>
			) : (
				<DesktopSiteHeader
					menus={menus}
					menuItems={menuItems}
					logo={logo}
					epConfig={epConfig}
				/>
			)}
			<ProgressBar />
			{hasStickyAudio && <StickyAudio block={stickyAudioBlock} />}
		</header>
	);
}

SiteHeader.propTypes = {
	desktop: PropTypes.bool,
	menus: PropTypes.shape({
		primary: PropTypes.arrayOf(PropTypes.object),
		region: PropTypes.arrayOf(PropTypes.object),
	}),
};
