import { Component } from 'react';
import ReactDOM from 'react-dom';

/**
 * @todo document this component
 */
export default class Modal extends Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.modalRoot = document.getElementById('modal-root');
	}

	componentDidMount() {
		this.modalRoot.appendChild(this.el);

		const modalClose = this.modalRoot.querySelector('.modal-close');
		const modalHeading = this.modalRoot.querySelector('.modal-heading');

		if (modalClose) {
			modalClose.focus();
		}

		if (!modalClose && modalHeading) {
			modalHeading.setAttribute('tabindex', -1);
			modalHeading.focus();
		}
	}

	componentWillUnmount() {
		this.modalRoot.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(this.props.children, this.el);
	}
}
