import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import networks from './networks';

/**
 * @todo document this component
 */
const SocialNav = ({ context }) => {
	const socialNetworks = useMemo(
		() =>
			Object.keys(networks).reduce((collection, network) => {
				if (network in networks && networks[network]) {
					return { ...collection, [network]: networks[network] };
				}

				return collection;
			}, {}),
		[],
	);
	const networkNumber = useMemo(() => Object.keys(socialNetworks).length, [socialNetworks]);

	return (
		<div className={context ? `social-nav social-nav--${context}` : 'social-nav'}>
			<ul
				className={classnames(
					context ? `social-nav__ul social-nav__ul--${context}` : 'social-nav__ul',
					`social-nav__ul--${networkNumber}`,
				)}
			>
				{Object.keys(socialNetworks).map((networkKey) => {
					const network = socialNetworks[networkKey];
					return (
						network && (
							<li className="social-nav__li" key={networkKey}>
								<a
									className="social-nav__link"
									href={network.url}
									target="_blank"
									rel="noopener noreferrer"
								>
									<span className="screen-reader-text">{network.name}</span>
									<network.icon className="sb-icon social-nav__icon" />
								</a>
							</li>
						)
					);
				})}
			</ul>
		</div>
	);
};

SocialNav.propTypes = {
	context: PropTypes.string,
};

export default SocialNav;
