export const parseUrl = (href) => {
	let url = href;
	let baseUrl = process.env.NEXT_PUBLIC_URL;

	if (!baseUrl.endsWith('/')) {
		baseUrl = `${baseUrl}/`;
	}

	if (!url.startsWith('http') && !url.startsWith('/')) {
		url = '/';
	}

	try {
		url = url.startsWith('/') ? new URL(href, baseUrl) : new URL(href);
	} catch (error) {
		console.error(error.message);
		url = new URL(href, baseUrl);
	}

	return url;
};

/**
 * @todo document this function
 * @param {*} history
 * @param {*} variable
 */
export function getQueryVariable(history, variable) {
	const query = history.location?.search?.substring(1);
	if (!query) {
		return false;
	}
	const vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}

	return false;
}

/**
 * @todo document this function
 * @param {*} e
 */
export const listenerNavArrowFocus = (e) => {
	// use arrow keys to select next primary item
	if (e.target.classList.contains('menu-item-link')) {
		const primaryMenu = document.querySelector('.primary-menu');
		const { nextSibling } = e.target.parentNode;
		const nextSiblingMenuItem = nextSibling ? nextSibling.querySelectorAll('a') : '';
		const nextSiblingMenuLink = nextSiblingMenuItem ? nextSiblingMenuItem[0] : '';

		const prevSibling = e.target.parentNode.previousSibling;
		const prevSiblingMenuItem = prevSibling ? prevSibling.querySelectorAll('a') : '';
		const prevSiblingMenuLink = prevSiblingMenuItem ? prevSiblingMenuItem[0] : '';

		const parentLinks = primaryMenu.querySelectorAll('.menu-item-link');
		const [firstParent] = parentLinks;
		const lastParent = parentLinks[parentLinks.length - 1];

		// Left and right arrow keys not being used at this time - enhancement recommendation
		if (e.which === 38) {
			e.preventDefault();
			// up
			if (prevSiblingMenuLink) {
				prevSiblingMenuLink.focus();
			} else {
				lastParent.focus();
			}
		} else if (e.which === 40) {
			e.preventDefault();
			// down
			if (nextSiblingMenuLink) {
				nextSiblingMenuLink.focus();
			} else {
				firstParent.focus();
			}
		}
	} else if (e.target.classList.contains('menu-item-subnav')) {
		const parentItem = e.target.parentNode;
		const parentContainer = parentItem.parentNode;

		const { nextSibling } = parentItem;
		const nextLinkItem = nextSibling ? nextSibling.querySelectorAll('a') : '';
		const nextLink = nextLinkItem ? nextLinkItem[0] : '';

		const prevSibling = parentItem.previousSibling;
		const prevLinkItem = prevSibling ? prevSibling.querySelectorAll('a') : '';
		const prevLink = prevLinkItem ? prevLinkItem[0] : '';

		const parentLinks = parentContainer.querySelectorAll('.menu-item-subnav');
		const [firstParent] = parentLinks;
		const lastParent = parentLinks[parentLinks.length - 1];

		// Left and right arrow keys not being used at this time - enhancement recommendation
		if (e.which === 38) {
			// up
			if (prevLink) {
				prevLink.focus();
			} else {
				lastParent.focus();
			}
		} else if (e.which === 40) {
			// down
			if (nextLink) {
				nextLink.focus();
			} else {
				firstParent.focus();
			}
		}
	}
};

/**
 * @todo document this function
 * @param {*} e
 */
export const listenerNavTabFocus = (e) => {
	// Keep tab inside mobile navigation
	const languagePicker = document.querySelector('.language-picker');

	if (languagePicker) {
		const lastLink = languagePicker.querySelector('.language-picker__toggle');
		const menuToggle = document.querySelector('.site-menu-toggle');

		if (lastLink === document.activeElement && e.which === 9) {
			e.preventDefault();
			menuToggle.focus();
		}
	}
};

/**
 * @todo document this function
 * @param {*} e
 */
export const languageModalTabFocus = (e) => {
	// Keep tab inside the language modal options
	const languageList = document.querySelector('.language-picker__modal_body');

	if (languageList) {
		const lastLink = languageList.querySelector('.language-picker__close');
		const menuHeading = document.querySelector('.modal-heading');

		if (menuHeading) {
			menuHeading.setAttribute('tabindex', -1);
		}

		if (lastLink === document.activeElement && e.which === 9) {
			e.preventDefault();
			menuHeading.focus();
		}
	}
};
