import { __ } from '@wordpress/i18n';

export const getGlobalLinks = () => {
	const privacyPolicy = {
		text: __('Privacy Policy', 'starbucks'),
		url: __('https://www.starbucks.com/terms/privacy-policy/', 'starbucks'),
	};

	const termsConditions = {
		text: __('Terms & Conditions', 'starbucks'),
		url: __('https://www.starbucks.com/terms/starbucks-terms-of-use/', 'starbucks'),
	};

	const starbucksMainSite = {
		text: __('Starbucks.com', 'starbucks'),
		url: __('https://www.starbucks.com', 'starbucks'),
	};

	const starbucksCareers = {
		text: __('Career Center', 'starbucks'),
		url: __('https://www.starbucks.com/careers', 'starbucks'),
	};

	const starbucksReserve = {
		text: __('Reserve', 'starbucks'),
		url: __('https://www.starbucksreserve.com/en-us', 'starbucks'),
	};

	const starbucksAtHome = {
		text: __('At Home', 'starbucks'),
		url: __('https://athome.starbucks.com', 'starbucks'),
	};

	return {
		privacyPolicy,
		termsConditions,
		starbucksMainSite,
		starbucksCareers,
		starbucksReserve,
		starbucksAtHome,
	};
};
