import * as React from 'react';
import PropTypes from 'prop-types';
import { __ } from '@wordpress/i18n';
import Hamburger from 'assets/icons/hamburger.svg';

const ToggleButton = React.forwardRef(({ active, onClick }, ref) => {
	return (
		<button
			ref={ref}
			onClick={onClick}
			aria-expanded={active ? 'true' : 'false'}
			aria-controls="menu-main-nav"
			id="site-menu-toggle"
			className="site-menu-toggle"
		>
			<span className="screen-reader-text">{__('Toggle Primary Menu', 'starbucks')}</span>

			<span className="site-menu-toggle__svg-wrap" aria-hidden={active ? 'true' : 'false'}>
				<Hamburger aria-hidden="true" className="sb-icon item-menu-toggle__svg top-line" />

				<Hamburger
					aria-hidden="true"
					className="sb-icon item-menu-toggle__svg bottom-line"
				/>
			</span>
		</button>
	);
});

ToggleButton.displayName = 'ToggleButton';

ToggleButton.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

export default ToggleButton;
