import * as React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';
import { trailingSlashItInternalLink } from 'utils/url';

export default function Head({ children, meta = {} }) {
	return (
		<NextHead>
			<title>{meta.title}</title>
			<meta
				name="description"
				content={meta.site?.description?.content || 'Starbucks Stories'}
			/>
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			{meta.canonical && (
				<link
					key="meta-canonical"
					rel="canonical"
					href={trailingSlashItInternalLink(meta.canonical)}
				/>
			)}

			{meta.site &&
				Object.keys(meta.site).map((x) => {
					const metaObj = meta.site[x];
					return (
						metaObj?.property &&
						metaObj?.content &&
						(metaObj.property.startsWith('og:') ? (
							<meta
								key={metaObj.property}
								property={metaObj.property}
								content={metaObj.content}
							/>
						) : (
							<meta
								key={metaObj.property}
								name={metaObj.property}
								content={metaObj.content}
							/>
						))
					);
				})}

			{meta.jsonld ? (
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: meta.jsonld }}
				/>
			) : null}
			{children}
		</NextHead>
	);
}

Head.propTypes = {
	children: PropTypes.node,
	meta: PropTypes.shape({
		title: PropTypes.string,
		canonical: PropTypes.string,
		jsonld: PropTypes.string,
		site: PropTypes.object,
	}),
};
