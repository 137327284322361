import * as React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

export default function RawHtml({ content = '', ...rest }) {
	const reactElements = parse(content, { trim: true });

	return React.cloneElement(reactElements, { ...reactElements.props, ...rest });
}

RawHtml.propTypes = {
	content: PropTypes.string,
};
