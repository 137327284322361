import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { EventQueueContext, EventQueueProvider } from 'context/EventQueueProvider';
import { ScrollContext, ScrollContextProvider } from 'context/ScrollContextProvider';

// eslint-disable-next-line no-unused-vars
export const ScrollTrackingComponent = (props) => {
	const addEvent = useContext(EventQueueContext);
	const percentScroll = useContext(ScrollContext);
	const router = useRouter();
	const pathname = router.asPath;

	const [roundedPercentScroll, setRoundedPercentScroll] = useState(Math.round(percentScroll));
	const [scrolledTwentyFive, setScrolledTwentyFive] = useState(false);
	const [scrolledFifty, setScrolledFifty] = useState(false);
	const [scrolledSeventyFive, setScrolledSeventyFive] = useState(false);
	const [scrolledOneHundred, setScrolledOneHundred] = useState(false);

	const pushEvent = (depth) => {
		addEvent(
			'_analytics' in global && 'getPageTemplate' in global._analytics
				? global._analytics.getPageTemplate()
				: pathname,
			`Scroll Depth - ${depth}%`,
			null,
			{ nonInteraction: true },
		);
	};

	useEffect(() => {
		if (roundedPercentScroll >= 25 && !scrolledTwentyFive) {
			pushEvent(25);
			setScrolledTwentyFive(true);
		} else if (roundedPercentScroll >= 50 && !scrolledFifty) {
			pushEvent(50);
			setScrolledFifty(true);
		} else if (roundedPercentScroll >= 75 && !scrolledSeventyFive) {
			pushEvent(75);
			setScrolledSeventyFive(true);
		} else if (roundedPercentScroll >= 100 && !scrolledOneHundred) {
			pushEvent(100);
			setScrolledOneHundred(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roundedPercentScroll]);

	useEffect(() => {
		setRoundedPercentScroll(Math.round(percentScroll));
	}, [percentScroll]);

	// When location changes, reset state.
	useEffect(() => {
		setScrolledTwentyFive(false);
		setScrolledFifty(false);
		setScrolledSeventyFive(false);
		setScrolledOneHundred(false);
	}, [pathname]);

	return null;
};

export default function ScrollTracking() {
	return (
		<EventQueueProvider>
			<ScrollContextProvider>
				<ScrollTrackingComponent />
			</ScrollContextProvider>
		</EventQueueProvider>
	);
}
