import { useState, useEffect } from 'react';

export default function useScrollBarWidth() {
	const [scrollBarWidth, setScrollBarWidth] = useState(0);

	useEffect(() => {
		setScrollBarWidth(window.innerWidth - document.documentElement.clientWidth);

		window.addEventListener('resize', () => {
			setScrollBarWidth(window.innerWidth - document.documentElement.clientWidth);
		});
	}, []);

	return `${scrollBarWidth}px`;
}
