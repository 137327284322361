import * as React from 'react';

/**
 * Usage prevents React Hydration client/server mismatch warnings
 * when using the window/document globals or DOM api's that are not
 * isomorphic.
 *
 * @returns {boolean}
 */
export default function useIsSsr() {
	const [isSsr, setIsSsr] = React.useState(true);

	React.useEffect(() => {
		setIsSsr(false);
	}, []);

	return isSsr;
}
