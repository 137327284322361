const useStickyAudio = (pageProps) => {
	const blocks = pageProps?.data?.post?.content?.blocks;
	const stickyAudio = blocks?.find((block) => block.name === 'starbucks-blocks/sticky-audio');

	return {
		hasStickyAudio: stickyAudio !== undefined,
		stickyAudioBlock: stickyAudio,
	};
};

export default useStickyAudio;
