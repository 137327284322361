import * as React from 'react';
import PropTypes from 'prop-types';
import RawHtml from 'components/Html//Raw';

const Svg = React.forwardRef(({ content, className, ...rest }, ref) => {
	return (
		<RawHtml
			ref={ref}
			content={content}
			className={`image-to-svg image-to-svg--replaced${className ? ` ${className}` : ''}`}
			{...rest}
		/>
	);
});

Svg.propTypes = {
	content: PropTypes.string.isRequired,
	className: PropTypes.string,
};

Svg.displayName = 'Svg';

export default Svg;
