import React, { Component } from 'react';
import { withRouter } from 'next/router';
import { __ } from '@wordpress/i18n';
import LanguageSwitcher from 'components/I18n/LanguageSwitcher/Menu';
import Modal from 'components/Modal';
import Globe from 'assets/icons/globe.svg';
import CaretDown from 'assets/icons/caret-down.svg';
import CloseIcon from 'assets/icons/plus.svg';

class LanguagePicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active: false,
		};

		this.languageToggle = React.createRef();
	}

	componentDidMount() {
		// Close language picker on ESC key.
		document.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				this.setState({
					active: false,
				});
			}
		});
	}

	componentDidUpdate() {
		// Close language picker on small to medium screens if navigation closed.
		if (!this.props.desktop && !this.props.menuState && this.state.active === true) {
			this.setState({
				active: false,
			});
		}
	}

	toggleModal = () => {
		if (this.state.active) {
			this.setState({ active: false });
			this.languageToggle.current.focus();
		} else {
			this.setState({ active: true });
			const modalHeading = document.querySelector('.language-picker__heading');

			if (modalHeading === true) {
				modalHeading.focus();
			}
		}
	};

	modalScreenClickIntent = (e) => {
		if (e.target.classList.contains('language-picker__modal_screen') === true) {
			this.setState({ active: false });
			this.languageToggle.current.focus();
		}
	};

	render() {
		const { menuState, menuItems, currentSite } = this.props;
		const { active } = this.state;

		const siteName = currentSite?.name ?? '';
		const regionName = currentSite?.region?.name ?? '';
		const currentLocation = `${regionName} (${siteName})`;

		return (
			<div className={`language-picker ${active ? 'language-picker--active' : ''}`}>
				<button
					className="language-picker__toggle"
					onClick={this.toggleModal}
					ref={this.languageToggle}
					aria-controls="language-picker"
					aria-expanded={this.state.active === true ? 'true' : 'false'}
				>
					<span className="screen-reader-text">Open Language Picker</span>

					<span className="language-picker__globe-icon">
						<Globe aria-hidden="true" />
					</span>
					<div className="language-picker__current_region">
						<span className="language-picker__current_region-label">
							{currentLocation}
						</span>

						<CaretDown aria-hidden="true" />
					</div>
				</button>

				{active && (
					<Modal targetEl={this.languageToggle}>
						<div
							className="language-picker__modal_screen"
							onClick={this.modalScreenClickIntent}
						>
							<div
								className={`language-picker__modal ${
									menuState === true ? 'language-picker__modal--open' : ''
								}`}
							>
								<div
									id="language-picker"
									className="language-picker__modal_body"
									aria-hidden={menuState === true ? 'false' : 'true'}
								>
									<h3 className="language-picker__heading modal-heading">
										{__('Choose Your Region', 'starbucks')}:
									</h3>

									<LanguageSwitcher menuItems={menuItems} />

									<button
										className="language-picker__close modal-close"
										onClick={this.toggleModal}
									>
										{__('Close', 'starbucks')}
										<CloseIcon aria-hidden="true" />
									</button>
								</div>
							</div>
						</div>
					</Modal>
				)}
			</div>
		);
	}
}

export default withRouter(LanguagePicker);
