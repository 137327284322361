import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { __ } from '@wordpress/i18n';
import { SkipNavLink } from 'components/Accessibility/SkipNav';
import Logo from 'components/Layout/Header/Logo';
import MobileMenu from 'components/Navigation/MobileMenu';
import ToggleButton from 'components/Navigation/ToggleButton';
import useToggle from 'hooks/useToggle';
import { getFooterLinks, legalLinksLinkItems } from 'utils/links-transformers';
import { getGlobalLinks } from 'components/Global';

export default function MobileSiteHeader({ menus, menuItems, logo, epConfig }) {
	const [isOpen, toggle, updateActive] = useToggle();
	const [logoClicked, setLogoClicked] = useState(false);
	const [footerLinkInfo, setFooterLinkInfo] = useState(getFooterLinks());
	const { domain: homeUrl = '/' } = menus?.currentSite || {};

	useEffect(() => {
		let _footerLinkInfo = getFooterLinks();

		if (Number(process.env.NEXT_PUBLIC_FOOTER_SB_ONLY) === 1) {
			_footerLinkInfo = {
				..._footerLinkInfo,
				general: [getGlobalLinks().starbucksMainSite],
			};
		}

		setFooterLinkInfo(_footerLinkInfo);
	}, []);

	const handleLogoClick = React.useCallback(() => {
		const navTrigger = document.querySelector('.site-menu-toggle');
		let newState = false;

		// Close navigation
		if (navTrigger && navTrigger.getAttribute('aria-expanded') === 'true') {
			newState = true;

			// Remove clicked tate
		} else if (navTrigger && logoClicked) {
			newState = false;
		}

		setLogoClicked(newState);
	}, [logoClicked]);

	const handleClickOutside = useCallback(
		(e) => {
			if (!e.target.closest('.site-navigation') && !e.target.closest('.site-menu-toggle')) {
				updateActive(false);
			}
		},
		[updateActive],
	);

	const handleResetLogo = () => {
		toggle();
	};

	const menuToggle = () => {
		if (isOpen) {
			document.body.style.overflow = null;
		} else {
			document.body.style.overflow = 'hidden';
		}

		toggle();
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.body.style.overflow = null;
			document.removeEventListener('click', handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className="sb-container--fluid sb-site-header__container">
			<SkipNavLink className="visually-hidden-focusable skip-link" id="primary">
				{__('Skip to content', 'starbucks')}
			</SkipNavLink>

			<ToggleButton active={isOpen} onClick={menuToggle} />

			<MobileMenu
				desktop={false}
				menus={menus}
				menu={menuItems}
				logoclicked={logoClicked}
				resetLogo={handleResetLogo}
				active={isOpen}
				onToggle={menuToggle}
				epConfig={epConfig}
				legalLinks={legalLinksLinkItems(footerLinkInfo.legal)}
			/>

			{logo && <Logo href={homeUrl} onClick={handleLogoClick} filepath={logo} />}
		</div>
	);
}

MobileSiteHeader.propTypes = {
	menus: PropTypes.shape({
		primary: PropTypes.arrayOf(PropTypes.object),
		region: PropTypes.arrayOf(PropTypes.object),
	}),
	menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};
