import * as React from 'react';

export default function useToggle(initialValue = false) {
	const [active, setActive] = React.useState(initialValue);
	const toggle = React.useCallback(() => setActive((isOpen) => !isOpen), []);

	const updateActive = (a) => {
		setActive(a);
	};

	return [active, toggle, updateActive];
}
