import dayjs from 'dayjs';
import { dayJsLocaleMapping } from 'utils/day-js-locale-mapping';

/**
 * @todo document this function
 * @param {*} date
 */
export const formatDate = (date) => {
	const dateObj = new Date(date);

	return dateObj.toLocaleString();
};

/**
 *
 * @param {*} date
 * @returns A formatted date. For US and Canada(English): October 01, 2018. For other languages: 01 October 2018.
 */
export const longDateFormat = (date, locale = 'en-us') => {
	const lang = dayJsLocaleMapping({ locale });
	if (lang && ['pt-br', 'fr-ca', 'es'].includes(lang)) {
		return dayjs(date).locale(lang).format('DD MMMM YYYY');
	}
	return dayjs(date).locale(lang).format('MMMM DD, YYYY');
};

/**
 *
 * @param {*} date
 * @returns A formatted date. For US and Canada(English): October 01, 2018 11:00am. For other languages: 01 October 2018 11:00am.
 */
export const longDateTimeFormat = (date, locale = 'en-us') => {
	const lang = dayJsLocaleMapping({ locale });

	if (lang && ['pt-br', 'fr-ca', 'es'].includes(lang)) {
		return dayjs(date).locale(lang).format('DD MMMM YYYY h:mm a');
	}
	return dayjs(date).locale(lang).format('MMMM DD, YYYY h:mm a');
};

/**
 *
 * @param {*} date
 * @returns A formatted date. For US and Canada(English): Oct 01, 2018. For other languages: 01 Oct 2018.
 */
export const longDateShortMonthFormat = (date, locale = 'en-us') => {
	const lang = dayJsLocaleMapping({ locale });

	if (lang && ['pt-br', 'fr-ca', 'es'].includes(lang)) {
		return dayjs(date).locale(lang).format('DD MMM YYYY');
	}
	return dayjs(date).locale(lang).format('MMM DD, YYYY');
};

const associatedPressMonths = [
	{
		from: 'January',
		to: 'Jan.',
	},
	{
		from: 'February',
		to: 'Feb.',
	},
	{
		from: 'August',
		to: 'Aug.',
	},
	{
		from: 'September',
		to: 'Sept.',
	},
	{
		from: 'October',
		to: 'Oct.',
	},
	{
		from: 'November',
		to: 'Nov.',
	},
	{
		from: 'December',
		to: 'Dec.',
	},
];

/**
 * @todo document this function
 * @param {*} date
 * @returns A formatted date. For US and Canada(English): Oct 1. For other languages: 1 Oct.
 */
export const medDateFormat = (date, locale = 'en-us') => {
	const lang = dayJsLocaleMapping({ locale });

	if (lang && ['en-ca', 'en-us'].includes(lang)) {
		let formattedDate = dayjs(date).locale(lang).format('MMMM D');

		// use AP month abbreviations by replacing regular months.
		associatedPressMonths.forEach(({ from, to }) => {
			formattedDate = formattedDate.replace(from, to);
		});

		return formattedDate;
	}
	return dayjs(date).locale(lang).format('DD MMMM');
};

/**
 * @todo document this function
 * @param {*} content
 */
export const getReadingTime = (content) => {
	const tagRegex = /(<([^>]+)>)/gi;
	const newContent = content?.toString().replace(tagRegex, '');
	const wordCount = newContent?.split(' ').length;
	const timeToRead = Math.ceil(wordCount / 300);

	return timeToRead;
};
