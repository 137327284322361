/**
 * Calculates the percentage of an element that has been read based on its
 * position in the viewport.
 *
 * @param {string} targetID - The ID of the target element.
 * @returns {number} The percentage of the target element that has been read. If the target element or the progress bar does not exist, it returns 0.
 */
export const getPercentRead = (targetID) => {
	const target = document.getElementById(targetID);
	const progress = document.getElementById('progress-bar');

	if (target && progress) {
		const progressTop = target.offsetTop;
		const targetData = target.getBoundingClientRect();
		const elHeight = targetData.height;
		const elBottom = targetData.bottom;
		const offset = document.documentElement.clientHeight * 0.5;

		const newHeight = elHeight - offset;
		const newBottom = elBottom - progressTop - offset;
		const newPercentage = (1 - newBottom / newHeight) * 100;

		return Math.min(100, newPercentage);
	}
	return 0;
};

/**
 * Checks if an element is in the viewport of the browser window.
 *
 * @param {object} el - The element to check.
 * @param {boolean} [updateOnce=true] - If true, the function will stop checking once the element has been viewed.
 * @param {number} [offset=0.5] - The percentage of the window height at which the element is considered in view.
 * @param {object} [differentEl] - An optional element that will receive the '-viewed' class instead of `el`.
 * @returns {boolean} Returns true if the element is in view, false otherwise.
 */
export const elInView = (el, updateOnce = true, offset = 0.5, differentEl) => {
	if (!el || !el.current?.classList) {
		return;
	}

	if (updateOnce && el.current.classList.contains('-viewed')) {
		return;
	}

	const elTop = el.current.getBoundingClientRect().top;
	const windowHeight = window.innerHeight || document.documentElement.clientHeight;
	const newWindowHeight = windowHeight * offset;
	const isInView = elTop < newWindowHeight;

	if (updateOnce && isInView) {
		if (differentEl?.current?.classList) {
			differentEl.current.classList.add('-viewed');
		} else {
			el.current.classList.add('-viewed');
		}
	}

	return isInView;
};

/**
 * Calculates the percentage of an element that is currently in view within
 * the viewport.
 *
 * @param {object} el - The element to check.
 * @returns {number} The percentage of the element that is in view. Returns 0 if the element is not in view.
 */
export const withinView = (el) => {
	const windowHeight = window.innerHeight;
	const docScroll = window.scrollY;
	const divPosition = el.offsetTop;
	const divHeight = el.offsetHeight;
	const hiddenBefore = docScroll - divPosition;
	const hiddenAfter = divPosition + divHeight - (docScroll + windowHeight);

	if (docScroll > divPosition + divHeight || divPosition > docScroll + windowHeight) {
		// Element not in view
		return 0;
	}
	let result = 100;

	if (hiddenBefore > 0) {
		result -= (hiddenBefore * 100) / divHeight;
	}

	if (hiddenAfter > 0) {
		result -= (hiddenAfter * 100) / divHeight;
	}

	// Element is in view
	return result;
};

/**
 * Scrolls the page to the element with the ID specified in the URL hash.
 * The scroll position is adjusted to account for the height of the site header.
 */
export const hashScrollEvent = () => {
	const { hash } = window.location;

	if (window.location.hash.length > 0) {
		const target = document.getElementById(hash.replace('#', ''));
		let offset = getOffsetTop(target);

		const navElements = document.getElementsByClassName('sb-site-header');

		if (navElements.length > 0) {
			offset -= navElements[0].scrollHeight;
		}

		window.scrollTo(0, offset);
	}
};

/**
 * Calculates the offset top position of an element.
 *
 * @param {object} el - The element to calculate the offset top position for.
 * @returns {number} The offset top position of the element.
 */
const getOffsetTop = (el) => {
	let offsetTop = 0;

	while (el) {
		offsetTop += el.offsetTop;
		el = el.offsetParent;
	}

	return offsetTop;
};
