import PropTypes from 'prop-types';
import { __ } from '@wordpress/i18n';
import { Svg } from 'components/Html';
import SocialNav from 'components/Social/Nav';
import SignUpForm from 'components/Newsletter';
import { getGlobalLinks } from 'components/Global';

import LanguageSwitcher from 'components/I18n/LanguageSwitcher/Menu';
import React, { useEffect, useState } from 'react';
import { footerLinkItems, getFooterLinks, legalLinksLinkItems } from 'utils/links-transformers';
import ScrollTracking from 'components/ScrollTracking';
import FooterInfo from './FooterInfo';

const FooterNav = ({ footerLinkItems }) => (
	<div className="sb-footer-nav">
		<ul className="sb-footer-nav__ul">{footerLinkItems}</ul>
	</div>
);

export default function SiteFooter({ menuItems = [], logo }) {
	const showSignUp = false && process.env.NEXT_PUBLIC_FEATURE_EMAIL_CAPTURE;
	const [footerLinkInfo, setFooterLinkInfo] = useState(null);

	useEffect(() => {
		let _footerLinkInfo = getFooterLinks();

		if (Number(process.env.NEXT_PUBLIC_FOOTER_SB_ONLY) === 1) {
			_footerLinkInfo = {
				..._footerLinkInfo,
				general: [getGlobalLinks().starbucksMainSite],
			};
		}

		setFooterLinkInfo(_footerLinkInfo);
	}, []);

	return (
		<footer className="sb-site-footer" role="contentinfo">
			<div className="sb-container--fluid sb-site-footer__container">
				<div className="sb-site-footer__logo-contain">
					{logo && (
						<Svg
							content={logo}
							className="sb-site-footer__logo"
							alt={__('Starbucks Siren Logo', 'starbucks')}
							style={{ height: 'auto', width: '100%', margin: 0 }}
							height={60}
							width={60}
						/>
					)}
				</div>

				{footerLinkInfo !== null && (
					<FooterNav footerLinkItems={footerLinkItems(footerLinkInfo?.general || [])} />
				)}

				{process.env.NEXT_PUBLIC_FEATURE_SOCIAL_ICONS && <SocialNav context="footer" />}

				{showSignUp && <SignUpForm context="footer" />}

				{process.env.NEXT_PUBLIC_FEATURE_SITE_SELECTOR && (
					<LanguageSwitcher menuItems={menuItems} />
				)}

				{footerLinkInfo !== null && (
					<FooterInfo
						context="footer"
						links={legalLinksLinkItems(footerLinkInfo?.legal || [])}
					/>
				)}
			</div>
			<ScrollTracking />
		</footer>
	);
}

SiteFooter.propTypes = {
	menuItems: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.any,
};
