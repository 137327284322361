import localFont from 'next/font/local'

const landerTall = localFont({
	src: [
		{
			style: 'normal',
			weight: '300',
			path: '../../public/fonts/LanderTall-Light.woff2',
		},
		{
			style: 'normal',
			weight: '300',
			path: '../../public/fonts/LanderTall-Light.woff',
		},
		{
			style: 'normal',
			weight: '400',
			path: '../../public/fonts/LanderTall-Regular.woff2',
		},
		{
			style: 'normal',
			weight: '400',
			path: '../../public/fonts/LanderTall-Regular.woff',
		},
		{
			style: 'normal',
			weight: '500',
			path: '../../public/fonts/LanderTall-SemiBold.woff2',
		},
		{
			style: 'normal',
			weight: '500',
			path: '../../public/fonts/LanderTall-SemiBold.woff',
		},
		{
			style: 'normal',
			weight: '600',
			path: '../../public/fonts/LanderTall-Bold.woff2',
		},
		{
			style: 'normal',
			weight: '600',
			path: '../../public/fonts/LanderTall-Bold.woff',
		},
	],
	variable: '--font-family--lander'
});

const soDoSans = localFont({
	src: [
		{
			style: 'normal',
			weight: '300',
			path: '../../public/fonts/SoDoSans-Light.woff2',
		},
		{
			style: 'normal',
			weight: '300',
			path: '../../public/fonts/SoDoSans-Light.woff',
		},
		{
			style: 'normal',
			weight: '400',
			path: '../../public/fonts/SoDoSans-Regular.woff2',
		},
		{
			style: 'normal',
			weight: '400',
			path: '../../public/fonts/SoDoSans-Regular.woff',
		},
		{
			style: 'normal',
			weight: '500',
			path: '../../public/fonts/SoDoSans-SemiBold.woff2',
		},
		{
			style: 'normal',
			weight: '500',
			path: '../../public/fonts/SoDoSans-SemiBold.woff',
		},
		{
			style: 'normal',
			weight: '600',
			path: '../../public/fonts/SoDoSans-Bold.woff2',
		},
		{
			style: 'normal',
			weight: '600',
			path: '../../public/fonts/SoDoSans-Bold.woff',
		},
		{
			style: 'normal',
			weight: '900',
			path: '../../public/fonts/SoDoSans-Black.woff2',
		},
		{
			style: 'normal',
			weight: '900',
			path: '../../public/fonts/SoDoSans-Black.woff',
		},
	],
	variable: '--font-family--sodo'
});

const soDoSerif = localFont({
	src: [
		{
			style: 'normal',
			weight: '300',
			path: '../../public/fonts/SoDoSerif-Light.woff2',
		},
		{
			style: 'normal',
			weight: '300',
			path: '../../public/fonts/SoDoSerif-Light.woff',
		},
		{
			style: 'normal',
			weight: '400',
			path: '../../public/fonts/SoDoSerif-Regular.woff2',
		},
		{
			style: 'normal',
			weight: '400',
			path: '../../public/fonts/SoDoSerif-Regular.woff',
		},
		{
			style: 'normal',
			weight: '600',
			path: '../../public/fonts/SoDoSerif-SemiBold.woff2',
		},
		{
			style: 'normal',
			weight: '600',
			path: '../../public/fonts/SoDoSerif-SemiBold.woff',
		},
	],
	variable: '--font-family--serif'
});

export { landerTall, soDoSans, soDoSerif }