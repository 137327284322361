import * as React from 'react';
import { trackEvent } from 'utils/seo';

export const EventQueueContext = React.createContext();

export const EventQueueProvider = ({ children }) => {
	const [eventQueue, setEventQueue] = React.useState([]);

	const addEvent = React.useCallback(
		(...args) => {
			setEventQueue([...eventQueue, args]);
		},
		[eventQueue],
	);

	React.useEffect(() => {
		if (!eventQueue.length) {
			return;
		}

		const [eventArgs, ...remainingEvents] = eventQueue;

		trackEvent(eventArgs);

		setEventQueue(remainingEvents);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventQueue.length]);

	return <EventQueueContext.Provider value={addEvent}>{children}</EventQueueContext.Provider>;
};
