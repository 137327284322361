import FacebookIcon from 'assets/icons/facebook.svg';
import TwitterIcon from 'assets/icons/twitter.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import YouTubeIcon from 'assets/icons/youtube.svg';
import { __ } from '@wordpress/i18n';

export const facebook = {
	name: __('Facebook', 'starbucks'),
	url: __('https://www.facebook.com/starbucks.stories', 'starbucks'),
	icon: FacebookIcon,
};
export const twitter = {
	name: __('Twitter', 'starbucks'),
	url: __('https://twitter.com/Starbucksnews', 'starbucks'),
	icon: TwitterIcon,
};
export const instagram = {
	name: __('Instagram', 'starbucks'),
	url: __('https://www.instagram.com/starbucks', 'starbucks'),
	icon: InstagramIcon,
};
export const youtube = {
	name: __('YouTube', 'starbucks'),
	url: __('https://www.youtube.com/starbucks', 'starbucks'),
	icon: YouTubeIcon,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	facebook: process.env.NEXT_PUBLIC_HIDE_FACEBOOK ? undefined : facebook,
	twitter: process.env.NEXT_PUBLIC_HIDE_TWITTER ? undefined : twitter,
	instagram,
	youtube: process.env.NEXT_PUBLIC_HIDE_YOUTUBE ? undefined : youtube,
};
