import * as React from 'react';
import { Link } from 'components/Html';
import Minus from 'assets/icons/minus.svg';
import Plus from 'assets/icons/plus.svg';
import CaretDown from 'assets/icons/caret-down.svg';
import SubMenu from './Submenu';

/**
 * @todo document this component
 */
class MenuItem extends React.Component {
	hasSubmenu = (menuItem) => {
		return menuItem.children && menuItem.children.length !== 0;
	};

	submenuClass = (menuItem) => {
		let classes = '';

		if (this.hasSubmenu(menuItem)) {
			classes += 'menu-item-has-children';
		}

		return classes;
	};

	// get inner height of subnav item
	getSubnavHeight = () => {
		if (this.props.desktop) {
			return 'none';
		}
		const parent = document.getElementById(`menu-item-${this.props.item.ID}`);

		const subnavContent = parent ? parent.querySelector('.submenu-content') : null;
		const innerHeight = subnavContent ? subnavContent.getBoundingClientRect().height : null;

		if (innerHeight) {
			return `${innerHeight}px`;
		}
		return null;
	};

	submenu = (menuItem) => {
		const isNavOpen = this.props.subopen === menuItem.ID;
		const initialMaxHeight = this.props.desktop ? 'none' : '0';
		const maxHeight = isNavOpen ? this.getSubnavHeight() : initialMaxHeight;

		if (this.hasSubmenu(menuItem)) {
			return (
				<SubMenu
					menu={menuItem.children}
					parentid={menuItem.ID}
					subopen={this.props.subopen}
					maxHeight={maxHeight}
					desktop={this.props.desktop}
					id={this.props.id}
					subnavclick={this.props.subnavclick}
					subnavfocus={this.props.subnavfocus}
					menuLinkClickAction={this.props.menuLinkClickAction}
				/>
			);
		}
	};

	// Icons Outuput
	parentIcon = () => {
		switch (this.props.desktop) {
			case true:
				return <CaretDown aria-hidden="true" className="sb-icon menu-item-link__icon" />;
			default:
				return (
					<>
						<Plus
							aria-hidden="true"
							className="sb-icon menu-item-link__icon menu-item-link__icon--open"
						/>
						<Minus
							aria-hidden="true"
							className="sb-icon menu-item-link__icon menu-item-link__icon--close"
						/>
					</>
				);
		}
	};

	custom = (menuItem) => {
		let menuUrl = menuItem.url.trim();
		if (menuUrl === '/' || (menuUrl[0] === '/' && menuUrl[1] !== '/')) {
			// Prevents matching protocol relative paths like //example.com
			return this.standard(menuItem);
		}

		if (menuItem.children && menuItem.children.length !== 0 && !menuItem.url) {
			// if has subnav and no url set in admin
			menuUrl = `#${menuItem.post_name}`;
			const isNavOpen = this.props.subopen === menuItem.ID;
			const hasFocusClass = isNavOpen ? 'child-has-focus' : '';

			return (
				<li
					id={`menu-item-${menuItem.ID}`}
					className={`menu-item menu-item-type-${menuItem.type} menu-item-${
						menuItem.ID
					} ${this.submenuClass(menuItem)} ${hasFocusClass}`}
				>
					<a
						href={`#submenu-of-${menuItem.ID}`}
						className="menu-item-link menu-item-link-hassubmenu"
						onFocus={(e) => this.props.subnavfocus(e, menuItem.ID, false)}
						onClick={(e) => this.props.subnavclick(e, false)}
						aria-haspopup="true"
						aria-expanded={hasFocusClass ? 'true' : 'false'}
					>
						{menuItem.title}
						<span className="menu-item-link__icon-contain">{this.parentIcon()}</span>
					</a>
					{this.submenu(menuItem)}
				</li>
			);
		}

		return (
			<li
				id={`menu-item-${menuItem.ID} `}
				className={`menu-item menu-item-type-${menuItem.type} menu-item-${
					menuItem.ID
				} ${this.submenuClass(menuItem)} `}
			>
				<Link
					href={menuUrl}
					onClick={(e) => this.props.menuLinkClickAction(e)}
					locale={false}
				>
					{menuItem.title}
				</Link>
				{this.submenu(menuItem)}
			</li>
		);
	};

	standard = (menuItem) => {
		const { url } = menuItem;

		return (
			<li
				id={`menu-item-${menuItem.ID} `}
				className={`menu-item menu-item-type-${
					menuItem.type
				} menu-item-object-custom menu-item-${menuItem.ID} ${this.submenuClass(menuItem)} `}
			>
				<Link
					href={url}
					className={this.props.child ? 'menu-item-subnav' : 'menu-item-link'}
					onClick={(e) => this.props.menuLinkClickAction(e)}
					locale={false}
				>
					{menuItem.title}
				</Link>
				{this.submenu(menuItem)}
			</li>
		);
	};

	render() {
		const menuItem = this.props.item;

		return menuItem.type === 'custom' ? this.custom(menuItem) : this.standard(menuItem);
	}
}

export default MenuItem;
