import * as React from 'react';
import { decodeEntities } from 'utils/decode-wrapper';
import PropTypes from 'prop-types';
import useIsMobile from 'hooks/useIsMobile';
import useIsSsr from 'hooks/useIsSsr';
import useScrollBarWidth from 'hooks/useScrollbarWidth';
import useStickyAudio from 'hooks/useStickyAudio';
import classnames from 'classnames';
import Header from './Header';
import Head from './Head';
import Footer from './Footer';

export default function FullWidthLayout({
	children,
	epConfig = null,
	menus = {},
	meta = {},
	pageProps = {},
}) {
	const isWideScreen = !useIsMobile('lg');
	const scrollBarWidth = useScrollBarWidth();
	const { hasStickyAudio } = useStickyAudio(pageProps);
	const { primary, region, currentSite } = menus;

	const headerLogo = currentSite?.logos?.primary ?? '';
	const footerLogo = currentSite?.logos?.secondary ?? '';
	const decodedTitle = decodeEntities(meta.title);
	const isSsr = useIsSsr();

	let fullUrl = '';

	if (!isSsr) {
		fullUrl = process.env.NEXT_PUBLIC_BASE_PATH;
	}

	const className = classnames('site', {
		'has-sticky-audio': hasStickyAudio,
	});

	return (
		<>
			<Head meta={meta}>
				<link rel="apple-touch-icon" href={`${fullUrl}/favicon.ico`} />
				<link rel="shortcut icon" href={`${fullUrl}/favicon.ico`} />
				<link rel="icon" href={`${fullUrl}/favicon.ico`} />
				<title>{decodedTitle}</title>
			</Head>
			<div className={className} style={{ '--scrollbar-width': scrollBarWidth }}>
				<Header
					menus={menus}
					menuItems={primary}
					desktop={isWideScreen}
					logo={headerLogo}
					epConfig={epConfig}
					pageProps={pageProps}
				/>
				<main className="site-main" id="primary">
					{children}
				</main>
				<Footer menuItems={region} logo={footerLogo} />
			</div>
		</>
	);
}

FullWidthLayout.propTypes = {
	children: PropTypes.node.isRequired,
	meta: PropTypes.shape({
		title: PropTypes.string,
		canonical: PropTypes.string,
		jsonld: PropTypes.string,
		site: PropTypes.object,
	}),
	menus: PropTypes.shape({
		primary: PropTypes.arrayOf(PropTypes.object),
		region: PropTypes.arrayOf(PropTypes.object),
	}),
};
