import { getLocaleOb, getRegionOb } from 'lib/i18n/utils';
import { getReadingTime } from 'utils/date';
import { hasVideo } from 'utils/post';

let lastTrackedPageView = '';

/**
 * Send a pageview to Google Analytics
 *
 * @param {*} pathname
 */
export const trackPageView = (pathname = '', fieldObj = {}) => {
	if (pathname.length === 0) {
		// Make sure to catch the search pages. These use a URL param.
		if (window.location.search.length > 0) {
			pathname = window.location.pathname + window.location.search;
		} else {
			pathname = window.location.pathname;
		}
	}

	// Only track a pageview when the path changes.
	if (lastTrackedPageView === pathname) {
		return;
	}
	lastTrackedPageView = pathname;

	if (
		window &&
		window._analytics &&
		window._analytics.trackPageView &&
		window._analytics.dataChannel.getAccountId
	) {
		window._analytics.trackPageView(pathname, fieldObj);
	}
};

/**
 * Send an event to Google Analytics
 *
 * @param {*} eventArgs
 */
export const trackEvent = (eventArgs) => {
	if (
		window &&
		window._analytics &&
		window._analytics.trackEvent &&
		window._analytics.dataChannel.getAccountId
	) {
		window._analytics.trackEvent(...eventArgs);
	}
};

/**
 * Analytics, check to ensure the library exists.
 *
 * @returns {Promise<R>|_interopRequireDefault.props.default}
 */
export const analyticsLoaded = () => {
	return new Promise((resolve, reject) => {
		if (typeof window === 'undefined') {
			return reject('Analytics does not exist');
		}

		let max = 150;
		const interval = setInterval(() => {
			--max;
			if (max === 0) {
				clearInterval(interval);
				reject(
					'_analytics' in window
						? 'Analytics missing "loaded" attribute'
						: 'Analytics does not exist',
				);
				return;
			}

			if ('_analytics' in window && window._analytics.loaded) {
				clearInterval(interval);
				resolve();
			}
		}, 100);
	});
};

export const STATUS = {
	homepage: 'Homepage',
	archive: 'Archive',
	search: 'Search',
	single: 'Single',
	error: '404',
};

function splitDate(date) {
	const dateWithoutTime = date.slice(0, -9);
	const [year, month, day] = dateWithoutTime.split('-');

	return { year, month, day };
}

function getQuarter(month) {
	const quarter = Math.floor(month / 3) + 1;
	return `Q${quarter}`;
}

export const setContentGrouping = (object, locale) => {
	const localeOb = getLocaleOb(locale);
	const regionOb = localeOb ? getRegionOb(localeOb.region) : null;

	if (typeof window !== 'undefined') {
		window.uo_data = window.uo_data || {};
	}

	if (typeof window === 'undefined' || object === undefined) {
		return;
	}

	if (object.type) {
		setContentGroupingPost(object, localeOb, regionOb);
	} else if (object.taxonomy) {
		setContentGroupingTaxonomy(object, localeOb, regionOb);
	}
};

export const setContentGroupingPost = (object, localeOb, regionOb) => {
	let { category, subCategory } = getPostCategories(object);

	const tags = getPostTags(object);
	const content = object.content?.rendered ? object.content.rendered : object.content;
	const type = object.type === 'attachment' ? 'Multimedia' : object.type;

	const postHasVideo = object.wistia_video || hasVideo(content);
	const postDate = type !== 'page' ? object.date : '';
	const author = Number.isInteger(object.author) ? '' : object.author;
	let postStatus = object.slug === 'homepage' ? STATUS.homepage : STATUS.single;

	// Homepage rules
	if (object.slug === 'homepage') {
		postStatus = STATUS.homepage;
		category = STATUS.homepage;
	}

	if (object.status === STATUS.search) {
		postStatus = object.status;
	}

	createContentGrouping(
		{
			title: object.title.rendered ? object.title.rendered : object.title,
			url: window.location.href,
			status: postStatus,
			type,
			author,
			postDate,
			averageReadingTime: getReadingTime(content),
			hasVideo: postHasVideo,
			category,
			subCategory,
			tags,
		},
		localeOb,
		regionOb,
	);
};

export const setContentGroupingTaxonomy = (object, localeOb, regionOb) => {
	const isSubTaxonomy = object.parent !== 0;

	const { category, subCategory } = getTermCategories(object);

	let type = isSubTaxonomy ? 'Sub Taxonomy' : 'Taxonomy';

	if (object.taxonomy === 'story_category') {
		type = 'Topic Page';
	}

	if (object.taxonomy === 'press_type') {
		type = 'Press';
	}

	if (object.taxonomy === 'leadership') {
		type = 'Leadership';
	}

	if (object.taxonomy === 'multimedia') {
		type = 'Multimedia';
	}

	createContentGrouping(
		{
			title: object.name,
			url: window.location.href,
			status: STATUS.archive,
			type,
			category,
			subCategory,
		},
		localeOb,
		regionOb,
	);
};

export const createContentGrouping = (options, locale, region) => {
	if (typeof window === 'undefined') {
		return;
	}

	const {
		url = '',
		title = '',
		status = STATUS.single,
		type = '',
		category = '',
		subCategory = '',
		author = '',
		postDate = '',
		averageReadingTime = '',
		hasVideo = false,
		tags = '',
	} = options;

	let dateDay = '';
	let dateMonth = '';
	let dateYear = '';
	let quarter = '';

	if (postDate !== '') {
		const date = splitDate(postDate);
		quarter = getQuarter(date.month);
		dateDay = date.day;
		dateMonth = date.month;
		dateYear = date.year;
	}

	window.uo_data = {
		environment: process.env.NODE_ENV,
		region: region?.name || '',
		language: locale?.language_label || '',
		appInfo: {
			pageUrl: url,
			contentTitle: title,
			contentStatus: status,
			contentType: type,
			contentCategory: category,
			contentSubCategory: subCategory,
			contentAuthor: author,
			contentPostDateDay: dateDay,
			contentPostDateMonth: dateMonth,
			contentPostDateYear: dateYear,
			contentPostDateQuarter: quarter,
			contentAverageReadTime: averageReadingTime,
			contentVideo: hasVideo,
			contentTags: tags,
		},
	};
};

const getPostCategories = (object) => {
	let taxonomy = '';

	// Map the taxonomy to the post type.
	if (object.type && object.type === 'press') {
		taxonomy = 'press_type';
	}

	if (object.type && object.type === 'story') {
		taxonomy = 'story_category';
	}

	// Bail if the mapping is not set.
	if (taxonomy.length === 0) {
		return [];
	}

	const postTermIds = object[taxonomy];

	const availableTerms = object.responseTerms || [];
	let postTerms = Object.values(availableTerms).filter((term) => postTermIds.includes(term.id));
	let parentTerms = [];

	// We also want to include a term's parent, even if not explicitly set on the post.
	for (var term of postTerms) {
		parentTerms = parentTerms.concat(
			Object.values(availableTerms).filter(
				// eslint-disable-next-line no-loop-func
				(availableTerm) => {
					// If the parent was already defined on the post, we don't need to re-add it.
					if (postTermIds.includes(availableTerm.id)) {
						return false;
					}

					if (term.parent === availableTerm.id) {
						return true;
					}

					return false;
				},
			),
		);
	}

	// Join the parent term with the other terms.
	postTerms = postTerms.concat(parentTerms);

	const category = postTerms.filter((term) => term.parent === 0)[0];
	const categoryName = category !== undefined ? category.name : '';
	const subCategory = postTerms.filter((term) => term.parent !== 0)[0];
	const subCategoryName = subCategory !== undefined ? subCategory.name : '';

	return {
		category: categoryName,
		subCategory: subCategoryName,
	};
};

const getTermCategories = (object, props) => {
	if (props?.terms === undefined && object?.responseTerms === undefined) {
		return [];
	}

	const availableTerms = Object.values(props?.terms || object?.responseTerms);

	const termID = object.id;
	const termParent = object.parent;

	const termCategories = availableTerms.filter((term) => {
		if (term.id === termID || term.id === termParent) {
			return true;
		}
		return false;
	});

	const category = termCategories.filter((term) => term.parent === 0)[0];
	const categoryName = category !== undefined ? category.name : '';
	const subCategory = termCategories.filter((term) => term.parent !== 0)[0];
	const subCategoryName = subCategory !== undefined ? subCategory.name : '';

	return {
		category: categoryName,
		subCategory: subCategoryName,
	};
};

/**
 * Get the tags of a post formated as a comma sepperated String
 *
 * @param {*} object
 * @param {*} props
 * @returns {string} Containing all the tags of the given post divided by commas
 */
function getPostTags(object) {
	const postTagIds = object.post_tag;

	// Bail early when no tags are present
	if (!postTagIds) {
		return '';
	}

	// const availableTerms = props.terms;
	const availableTerms = [];

	const postTerms = Object.values(availableTerms).filter((term) => postTagIds.includes(term.id));

	const postTagTitles = postTerms.map((tag) => tag.name).sort();

	return postTagTitles.join(', ');
}
