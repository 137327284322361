const { DOMAINS } = require('./domains');
const { LOCALES } = require('./locales');

const REGIONS = [
	{ id: 'usa', name: 'USA', order: 1, locales: ['en-us'] },
	{ id: 'emea', name: 'EMEA', order: 3, locales: ['emea'] },
	{ id: 'asia', name: 'Asia', order: 5, locales: ['asia'] },
	{ id: 'japan', name: 'Japan', order: 6, locales: ['ja'] },
	{ id: 'canada', name: 'Canada', order: 2, locales: ['en-ca', 'fr'] },
	{
		id: 'latin-america',
		name: 'Latin America',
		order: 4,
		locales: ['es', 'br', 'en-es'],
	},
];

const regions = REGIONS.map((region) => {
	const sites = LOCALES.filter((locale) => locale.region === region.id).map((x) => {
		const domain = getSiteUrl(x.domain, x.id);

		return {
			name: x.name,
			locale: x.id,
			hreflang: x.locale,
			default: x.default,
			domain,
			region: { id: region.id, name: region.name },
		};
	});

	return { ...region, sites };
});

function getSiteUrl(id, locale) {
	const site = DOMAINS.find((x) => x.id === id);
	const protocol = site.http ? 'http://' : 'https://';
	const url = new URL(`${protocol}${site.domain}/`);

	url.port = site.http ? process.env.PORT : '';
	url.pathname = locale === site.defaultLocale ? '' : `/${locale}`;

	return url.href;
}

module.exports = { regions, getSiteUrl };
