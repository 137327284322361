import React, { useContext } from 'react';
import { ScrollContext, ScrollContextProvider } from 'context/ScrollContextProvider';

const ProgressBarComponent = () => {
	const percentScroll = useContext(ScrollContext);

	const elStyle = {
		transform: `translateX( ${percentScroll}% )`,
	};

	return (
		<div id="progress-bar" className="progress-bar">
			<span className="progress-bar__bar" style={elStyle} />
		</div>
	);
};

export default function ProgressBar() {
	return (
		<ScrollContextProvider>
			<ProgressBarComponent />
		</ScrollContextProvider>
	);
}
