import * as React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';

const Link = React.forwardRef(({ children, disabled = false, ...rest }, ref) => {
	if (!rest.href) {
		return '';
	}

	// check if url is relative
	const isRelative = rest.href.startsWith('/') || rest.href.startsWith('#');

	// Fixes bug in Asia and EMEA sites where links are not being replaced correctly. Link replacement on this
	// site is a mess, and this late in the site's life, it's not worth digging into the root cause. See https://tenup.teamwork.com/app/tasks/19523782
	if (isRelative) {
		rest.href = rest.href.replace('/asia/', '/');
		rest.href = rest.href.replace('/emea/', '/');
	}

	// If no rel attribute is supplied but were opening a new tab, add the rel attribute
	// target and rel attributes are only added on external links
	if (!rest.rel && rest.target && rest.target === '_blank') {
		rest.rel = 'noopener noreferrer';
	}

	return (
		<NextLink
			href={rest.href}
			ref={ref}
			onClick={rest.onClick}
			className={rest.className}
			{...(rest.target ? { target: rest.target } : {})}
			{...(rest.rel ? { rel: rest.rel } : {})}
			{...(disabled ? { 'aria-disabled': true } : {})}
			{...(disabled ? { disabled: true } : {})}
			passHref={false}
			legacyBehavior={false}
			{...rest}
		>
			{children}
		</NextLink>
	);
});

Link.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
};

Link.displayName = 'Link';

export default Link;
