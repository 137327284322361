import * as React from 'react';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import 'normalize.css';
import 'assets/css/frontend/style.scss';
import FullWidthLayout from 'components/Layout/FullWidth';
import runAnalytics from 'utils/analytics';
import { trailingSlashItInternalLink } from 'utils/url';
import NProgress from 'nprogress';
import { dayJsLocaleMapping } from 'utils/day-js-locale-mapping';
import dayjs from 'dayjs';
import { landerTall, soDoSans, soDoSerif } from 'utils/fonts'; // do not remove.

import { createI18n, setLocaleData } from '@wordpress/i18n';
import { I18nProvider } from '@wordpress/react-i18n';

require('dayjs/locale/pt-br');
require('dayjs/locale/fr-ca');
require('dayjs/locale/es');

const gtmId = process.env.NEXT_PUBLIC_GTM_ID;

// progress loader.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export default function App({ Component, pageProps }) {
	const router = useRouter();
	const firstRender = React.useRef(true);
	const { locale } = router;

	const lang = dayJsLocaleMapping({ locale });
	dayjs.locale(lang);

	const messages = React.useMemo(() => pageProps?.translation ?? [], [pageProps?.translation]);

	// load and activate needs to run on first render (server) to avoid hydration error
	if (pageProps.translation && firstRender.current) {
		setLocaleData(pageProps.translation, 'starbucks');
		firstRender.current = false;
	}

	// Initialize Translations
	React.useEffect(() => {
		if (!router.isFallback) {
			setLocaleData(pageProps.translation, 'starbucks');
		}
	}, [locale, messages, router.isFallback]);

	// Add i18n body class
	React.useEffect(() => {
		const regx = new RegExp('\\b' + 'lang' + '[^ ]*[ ]?\\b', 'g');

		const wrapper = document.querySelector('body');
		wrapper.className = wrapper.className.replace(regx, '');
		wrapper.classList.add(`lang-${locale}`);
	});

	// Run Analytics
	React.useEffect(() => {
		if (!gtmId) {
			return;
		}

		const handleRouteChange = () => {
			runAnalytics();
		};

		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	// Add page number to archive pagination page canonicals
	React.useEffect(() => {
		const updateCanonical = () => {
			if (pageProps?.data?.archive?.pagination) {
				const { currentPage } = pageProps.data.archive.pagination;

				// Only apply to pages greater than 1
				if (currentPage > 1 && pageProps?.meta?.canonical) {
					const canonical = trailingSlashItInternalLink(pageProps.meta.canonical);
					pageProps.meta.canonical = `${canonical}page/${currentPage}`;
				}
			}
		};

		// Update canonical on app load
		updateCanonical();

		// Handle canonical change on route change
		router.events.on('routeChangeComplete', updateCanonical);
		return () => {
			router.events.off('routeChangeComplete', updateCanonical);
		};
	}, [router.events, pageProps]);

	return (
		<I18nProvider i18n={createI18n(locale)}>
			<FullWidthLayout
				meta={pageProps.meta}
				menus={pageProps.menus}
				epConfig={pageProps.epConfig}
				pageProps={pageProps}
			>
				{gtmId && (
					<>
						<Script
							id="gtm"
							strategy="afterInteractive"
							src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
							onLoad={() => runAnalytics()}
						/>
						<Script id="gtm-init" strategy="afterInteractive">
							{`
								window.dataLayer = window.dataLayer || [];
								window.dataLayer.push({'gtm.start': new Date().getTime(),event:'gtm.js'});
							`}
						</Script>
					</>
				)}
				<Component {...pageProps} />
			</FullWidthLayout>
		</I18nProvider>
	);
}
