import * as React from 'react';
import PropTypes from 'prop-types';
import { __ } from '@wordpress/i18n';
import { Link, Svg } from 'components/Html';

export default function Logo({ href, onClick, filepath = '' }) {
	return (
		<Link
			href={href}
			locale={false}
			onClick={onClick}
			id="site-logo"
			className="sb-site-header__logo sb-site-header__logo"
			style={{ display: 'flex' }}
		>
			<span className="screen-reader-text">
				{__('Starbucks News and Stories', 'starbucks')}
			</span>
			<Svg
				content={filepath}
				aria-hidden="true"
				style={{ height: 'auto', width: '100%', margin: 0 }}
			/>
		</Link>
	);
}

Logo.propTypes = {
	href: PropTypes.string,
	onClick: PropTypes.func,
};
