import * as React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default function Heading({ children, level = 2, className = 'component-header', ...rest }) {
	const id = rest.id || uuidv4();
	const Element = `h${level}`;

	return (
		<Element id={`h${level}--${id}`} className={className}>
			{children}
		</Element>
	);
}

Heading.propTypes = {
	children: PropTypes.node,
	level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
	className: PropTypes.string,
};
