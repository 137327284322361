import baseNormalizeUrl from 'normalize-url';

export const normalizeUrl = (url, { removeTrailingSlash = false, ...rest } = {}) => {
	return baseNormalizeUrl(url, { removeTrailingSlash, ...rest });
};

export const toSlug = (href) => {
	if (!href) {
		return '';
	}
	const isAbsolute = href?.startsWith('http') || false;
	const baseUrl = normalizeUrl(process.env.NEXT_PUBLIC_URL, {
		removeTrailingSlash: true,
	});

	return (() => {
		try {
			const urlParts = isAbsolute ? new URL(normalizeUrl(href)) : new URL(href, baseUrl);

			return urlParts.pathname;
		} catch (error) {
			console.error(`Error parsing: ${href}`, error.message);

			return '/';
		}
	})();
};

export const isAnchorLink = (href) => {
	if (typeof href !== 'string') {
		return false;
	}

	if (href[0] === '#') {
		return true;
	}

	let tempUrl = href;

	if (!href.startsWith('http://') && !href.startsWith('https://')) {
		tempUrl = `https://example.com/${href}`;
	}

	try {
		const urlObject = new URL(tempUrl);
		return urlObject.hash.length > 0;
	} catch (exc) {
		return false;
	}

	return false;
};

export const isInternalLink = (href, domainLocales = []) => {
	const isAbsolute = href?.startsWith('http') || false;
	// don't mess with common static files
	const isStaticFile =
		/\.(js|css|png|jpg|jpeg|gif|ico|mp3|mov|tif|tiff|swf|txt|html|pdf|doc|docx)$/i.test(href);
	// don't mess with wp upload resources
	const isUploadFolder = /\/uploads\/\d{4}\/\d{2}/.test(href);
	const isMailLink = href?.startsWith('mailto:') || false;

	if (isStaticFile || isUploadFolder || isMailLink) {
		return false;
	}

	const baseUrls = [
		process.env.NEXT_PUBLIC_URL,
		process.env.NEXT_PUBLIC_WP_API_URL,
		...domainLocales,
	].filter(Boolean);
	const [baseUrl] = baseUrls;

	const normalizedBasedUrls = baseUrls.map((url) =>
		normalizeUrl(url, {
			removeTrailingSlash: true,
		}),
	);
	const baseUrlsParts = normalizedBasedUrls.map((url) => {
		try {
			return new URL(url);
		} catch (e) {
			return url;
		}
	});
	const expectedHosts = baseUrlsParts.map(({ host }) => host);
	const knownHosts = [
		'stories.starbucks.com',
		'staging.starbucksarchive.10upmanaged.com',
		'historias.starbucks.com',
		'stories.starbucks.ca',
		'stories.starbucks.co.jp',
		'next-staging.stbu.10upmanaged.com',
		'next-production.stbu.10upmanaged.com',
		'stories.sbstoriesstage.com',
		'localhost',
	];

	try {
		const urlParts = isAbsolute ? new URL(normalizeUrl(href)) : new URL(href, baseUrl);

		// assumes internal link if url matches the api url or
		// if matches the app's public url or if it's in a know list domains
		// This allow the app to run properly against a env non-mathing api env (eg local with prod)
		return expectedHosts.includes(urlParts.host) || knownHosts.includes(urlParts.host);
	} catch (error) {
		console.error(`Error parsing: ${href}`, error.message);

		return false;
	}
};

/**
 * Normalize internal links.
 *
 * @param {string} link Raw link.
 * @returns {string} Normalized link (if internal).
 */
export const trailingSlashItInternalLink = (link) => {
	return isInternalLink(link) ? `${normalizeUrl(link, { removeTrailingSlash: true })}/` : link;
};

/**
 * Normalize links.
 *
 * @param {string} link Raw link.
 * @returns {string} Normalized link.
 */
export const trailingSlashIt = (link) => {
	return `${normalizeUrl(link, { removeTrailingSlash: true })}/`;
};

/**
 * Get domain from link.
 *
 * @param {string} href Link.
 * @returns {string|null}
 */
export const getDomainFromLink = (href) => {
	try {
		if (!href?.startsWith('http://') && !href.startsWith('https://')) {
			return null;
		}

		const url = new URL(href);

		return url.hostname || null;
	} catch (exc) {
		console.log('getDomainFromLink exception', exc);
	}
};

/**
 * Remove trailing slash from the pathname.
 *
 * @param {string } href Link.
 * @returns {string}
 */
export const removeTrailingSlash = (href) => {
	return href.replace(/\/$/, '');
};
