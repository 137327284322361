import { LOCALES } from 'lib/i18n/config/locales';
import { regions } from 'lib/i18n/config/regions';
import { DOMAINS } from 'lib/i18n/config/domains';

export function getLocale(id) {
	const item = LOCALES.find((x) => x.id === id);

	return item.locale;
}

export function getLocaleOb(id) {
	const item = LOCALES.find((x) => x.locale === id);

	return item;
}

export function getRegionOb(id) {
	const item = regions.find((x) => x.id === id);

	return item;
}

const mapLocaleToRegion = locale => {
	switch (locale) {
		case 'en':
			return 'en-us';
		case 'br':
			return 'es-br';
		case 'fr':
			return 'fr-ca';
		case 'emea':
			return 'en-emea';
		case 'asia':
			return 'en-asia';
		default:
			return locale;
	}

	return locale;
};

export async function loadTranslation(locale) {
	const nextLocale = mapLocaleToRegion(locale);

	try {
		const response = await import(`src/translations/locales/${nextLocale}/messages.json`);
		const translations = await response;
		return JSON.parse(JSON.stringify(translations)).locale_data.messages;
	} catch (error) {
		console.error('Error loading translations:', error);
		return null;
	}
}

/**
 * Get domain config by locale.
 *
 * @param {string} locale Locale.
 * @returns {{defaultLocale: string, locales: string[], domain: string, http: boolean | boolean, id: string} | {defaultLocale: string, locales: string[], domain: string, http: boolean | boolean, id: string} | {defaultLocale: string, locales: string[], domain: string, http: boolean | boolean, id: string} | {defaultLocale: string, locales: [string], domain: string, http: boolean | boolean, id: string}}
 */
export function getDomainByLocale(locale) {
	return DOMAINS.find((domain) => domain.locales.includes(locale));
}
