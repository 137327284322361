import PropTypes from 'prop-types';

const SkipNavLink = ({ children = 'Skip to content', id = 'maincontent', ...props }) => {
	return (
		<a {...props} href={`#${id}`}>
			{children}
		</a>
	);
};

SkipNavLink.propTypes = {
	children: PropTypes.node,
	id: PropTypes.string,
};

export { SkipNavLink };
