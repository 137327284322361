import React, { Component } from 'react';
import { __ } from '@wordpress/i18n';
import { baseUrl } from 'utils/api';
import ChevronRight from 'assets/icons/chevron-right.svg';
import Check from 'assets/icons/check.svg';

/**
 * @todo document this component
 */
class SignUpForm extends Component {
	state = {
		email: '',
		lists: {
			stories: false,
			alerts: true,
		},
		subscription: '',
		validation: {},
		resultMessage: '',
	};

	/**
	 * @todo document this method
	 * @param {*} context
	 */
	static decorateClasses(context) {
		if (!context) {
			return {
				containerClass: '',
				formClass: '',
				labelClass: '',
				inputClass: '',
				submitClass: '',
			};
		}
		return {
			containerClass: ` sb-signup--${context}`,
			formClass: ` sb-signup__form--${context}`,
			labelClass: ` sb-signup__label--${context}`,
			inputClass: ` sb-signup__input--${context}`,
			submitClass: ` sb-signup__submit--${context}`,
		};
	}

	/**
	 * @todo document this method
	 * @param {*} e
	 */
	submitForm = (e) => {
		e.preventDefault();
		const { email, subscription } = this.state;
		const payload = {
			email,
			lists: this.selectedLists(),
		};

		if (subscription === 'pending') {
			return;
		}

		this.setState({ resultMessage: '' });

		if (!this.validateForm()) {
			return; // validation errors
		}

		this.setState({ subscription: 'pending' });

		fetch(`/api/subscriptions`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error(); // Request completed with non-2xx response
			})
			.then((data) => {
				if (data.success) {
					this.subscribeSuccess(data.message);
				} else {
					this.subscribeFailure(data.message);
				}
			})
			// eslint-disable-next-line no-unused-vars
			.catch((error) => {
				this.subscribeFailure(
					__(
						'There was a problem processing your subscription. Please try again.',
						'starbucks',
					),
				);
			});
	};

	/**
	 * @todo document this method
	 */
	selectedLists() {
		return Object.keys(this.state.lists).filter((key) => this.state.lists[key]);
	}

	/**
	 * @todo document this method
	 */
	validateForm() {
		const validation = {};

		if (!this.state.email || !this.state.email.match(/@/)) {
			validation.email = __('Please provide a valid email address.', 'starbucks');
		}

		if (!this.selectedLists().length) {
			validation.lists = __('Please select at least one list to subscribe to.', 'starbucks');
		}

		this.setState({ validation });

		return Object.keys(validation).length === 0;
	}

	/**
	 * @todo document this method
	 * @param {*} message
	 */
	subscribeSuccess(message) {
		this.setState({
			subscription: 'success',
			resultMessage: message,
		});
	}

	/**
	 * @todo document this method
	 * @param {*} message
	 */
	subscribeFailure(message) {
		this.setState({
			subscription: 'failure',
			resultMessage: message,
		});
	}

	signUpMessage = () => {
		if (this.props.context === 'footer') {
			return __('Subscribe to weekly stories about coffee.', 'starbucks');
		}
		return __(
			'Subscribe to get Starbucks stories and news delivered to your inbox.',
			'starbucks',
		);
	};

	signUpOptions = () => {
		const { lists, validation, subscription } = this.state;
		const loading = subscription === 'pending';

		if (this.props.context === 'footer') {
		} else {
			return (
				<fieldset className="sb-form__fieldset sb-form__checkbox-group" role="group">
					<legend className="sb-form__checkbox-group-label sb-signup__label">
						{__('Working a beat? Select only what\u2019s most important', 'starbucks')}
					</legend>

					<label
						className="sb-form__checkbox-label sb-signup__check-label"
						htmlFor="sb-signup__check--stories"
					>
						<input
							className="sb-form__checkbox-checkbox"
							id="sb-signup__check--stories"
							type="checkbox"
							checked={lists.stories}
							onChange={() => {
								this.setState({ lists: { ...lists, stories: !lists.stories } });
							}}
							disabled={loading}
						/>

						<span className="sb-form__checkbox-checkmark">
							<Check className="sb-icon sb-signup__icon" aria-hidden="true" />
						</span>
						<span className="sb-form__checkbox-label-text">
							{__('Starbucks Stories', 'starbucks')}
							<span>
								{__(
									'Coffee farming and sources, Starbucks drinks, and recipes',
									'starbucks',
								)}
							</span>
						</span>
					</label>

					<label
						className="sb-form__checkbox-label sb-signup__check-label"
						htmlFor="sb-signup__check--alerts"
					>
						<input
							className="sb-form__checkbox-checkbox"
							id="sb-signup__check--alerts"
							type="checkbox"
							checked={lists.alerts}
							onChange={() => {
								this.setState({ lists: { ...lists, alerts: !lists.alerts } });
							}}
							disabled={loading}
						/>

						<span className="sb-form__checkbox-checkmark">
							<Check className="sb-icon sb-signup__icon" aria-hidden="true" />
						</span>
						<span className="sb-form__checkbox-label-text">
							{__('Media Alerts', 'starbucks')}

							<span>{__('Media updates', 'starbucks')}</span>
						</span>
					</label>
					{validation.lists && <span className="sb-form__error">{validation.lists}</span>}
				</fieldset>
			);
		}
	};

	render() {
		const { containerClass, formClass, labelClass, inputClass, submitClass } =
			SignUpForm.decorateClasses(this.props.context);
		const { subscription, validation, resultMessage } = this.state;
		const loading = subscription === 'pending';
		const isValid = Object.keys(validation).length ? 'invalid' : 'valid';

		const inputId = this.props.context ? inputClass : 'sb-signup__input';
		let submitDescribedBy = 'newsletter-submit-label';
		if (loading) {
			submitDescribedBy = 'sb-form-loading';
		} else if (resultMessage) {
			submitDescribedBy = 'sb-form-message';
		}

		return (
			<div className={`sb-signup${containerClass} sb-signup-sub--${subscription}`}>
				<form
					className={`sb-signup__form${formClass} sb-signup__form--${isValid}`}
					onSubmit={this.submitForm.bind(this)}
					noValidate
					aria-label={__('Newsletter Signup', 'starbucks')}
					aria-describedby={submitDescribedBy}
					data-form-type="Newsletter"
				>
					<label
						id="newsletter-submit-label"
						className={`sb-signup__label${labelClass}`}
						htmlFor={inputId}
					>
						{this.signUpMessage()}
					</label>
					<input
						id={inputId}
						className={`sb-input sb-signup__input ${inputClass}`}
						type="email"
						value={this.state.email}
						onChange={(event) => this.setState({ email: event.target.value })}
						placeholder={__('Enter your email address', 'starbucks')}
						disabled={loading}
						required
						aria-describedby={validation.email && 'sb-form-email-invalid'}
					/>
					<button
						className={`sb-button sb-signup__submit${submitClass}`}
						disabled={loading}
						type="submit"
						aria-describedby={submitDescribedBy}
					>
						<span className="screen-reader-text">{__('Submit', 'starbucks')}</span>
						<ChevronRight className="sb-icon sb-signup__icon" aria-hidden="true" />
					</button>
					{loading && (
						<span id="sb-form-loading" className="sb-form__loading">
							Loading
						</span>
					)}
					{validation.email && (
						<div id="sb-form-email-invalid" className="sb-form__error">
							{validation.email}
						</div>
					)}
					{resultMessage && (
						<div
							id="sb-form-message"
							className={`sb-form__result${
								subscription ? ` sb-form__result--${subscription}` : ''
							}`}
						>
							{resultMessage}
						</div>
					)}
				</form>
			</div>
		);
	}
}

// export default withRouter( SignUpForm );
export default SignUpForm;
