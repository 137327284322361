import { sprintf, __ } from '@wordpress/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { AutosuggestField, ElasticPressProvider, SearchField } from '@10up/elasticpress-react';
import Search from 'assets/icons/search.svg';
import ChevronRightThin from 'assets/icons/chevron-right-thin.svg';
import { transformLink } from 'lib/wp/cpt/shared/utils';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import searchQuery from './SearchQuery';

export const PageSearchForm = (props) => {
	const { epConfig, placeholder } = props;
	const [autosuggestCollapsed, setAutosuggestCollapsed] = useState(false);
	const [currentSearchTerm, setCurrentSearchTerm] = useState(props.currentValue || '');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const router = useRouter();
	const storedSearchTerm = useRef(props.currentValue || '');
	const FieldComponent = props.disableAutosuggest ? SearchField : AutosuggestField;

	const onSearchSubmit = (e) => {
		setAutosuggestCollapsed(true);
		setCurrentSearchTerm('');
		storedSearchTerm.current = '';
		setIsSubmitted(true);
		if (typeof props.onSubmit === 'function') {
			return props.onSubmit(e);
		}

		e.preventDefault();
		const searchTerm = e?.target?.elements?.searchPhrase?.value;
		if (searchTerm) {
			router.push(`/search/${searchTerm.replace(' ', '+')}`);
		}

		if (typeof props.onMobileSearchSubmit === 'function') {
			return props.onMobileSearchSubmit(e);
		}
	};

	useEffect(() => {
		setIsSubmitted(false);
	}, [router]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	});

	const handleClickOutside = (e) => {
		if (!e.target.closest(`.sb-search__form--${props.context}`)) {
			unfocusInput();
		} else {
			focusInput();
		}
	};

	const focusInput = () => {
		if (props.clearSearchTermOnLeave) {
			setCurrentSearchTerm(storedSearchTerm.current);
			if (storedSearchTerm.current?.length >= 3) {
				setAutosuggestCollapsed(false);
			}
		}
	};

	const unfocusInput = () => {
		if (props.clearSearchTermOnLeave) {
			setCurrentSearchTerm('');
		}
		setAutosuggestCollapsed(true);
	};

	if (!epConfig) {
		return null;
	}

	let timer = null;

	return (
		<ElasticPressProvider
			node={epConfig?.node?.slice(0, -1)}
			indexName={epConfig?.indexName}
			loadInitialData={false}
			query={searchQuery}
			endpoint="/_search"
		>
			<div className={`sb-search sb-search--${props.context}`}>
				<form
					className={`sb-search__form sb-search__form--${props.context}`}
					onSubmit={onSearchSubmit}
					role="search"
					aria-label={props.context}
					data-form-type="Site Search"
					onFocus={() => {
						if (timer) {
							clearTimeout(timer);
						}
						focusInput();
					}}
					onBlur={() => {
						timer = setTimeout(() => {
							unfocusInput();
						}, 500);
					}}
				>
					<Search className="sb-icon sb-search__input-icon" />
					<label className="screen-reader-text" id={`sb-search-${props.context}`}>
						{sprintf(__('Site search %s', 'starbucks'), props.context)}
					</label>

					<FieldComponent
						name="searchPhrase"
						searchTerm={currentSearchTerm}
						collapsed={currentSearchTerm?.length >= 3 ? autosuggestCollapsed : true}
						placeholder={
							placeholder?.length === 0
								? __('Search by keyword', 'starbucks')
								: placeholder
						}
						minSearchCharacters={3}
						onItemRender={(item) => {
							return {
								...item,
								permalink: transformLink(item.permalink),
							};
						}}
						autoComplete="off"
						onKeyUp={(e) => {
							const { value } = e.target;
							if (value?.length < 3) {
								setAutosuggestCollapsed(true);
							} else if (!isSubmitted) {
								setAutosuggestCollapsed(false);
							}
							if (props.clearSearchTermOnLeave) {
								storedSearchTerm.current = value;
							}
							setCurrentSearchTerm(value);
							return true;
						}}
					/>

					<button
						className={`sb-button sb-search-submit sb-search-submit--${props.context}`}
						type="submit"
					>
						<span className="screen-reader-text">{__('Search', 'starbucks')}</span>
						<ChevronRightThin className="sb-icon sb-search-submit-icon" />
					</button>
				</form>
			</div>
		</ElasticPressProvider>
	);
};

PageSearchForm.propTypes = {
	epConfig: PropTypes.shape({}).isRequired,
	placeholder: PropTypes.string,
	context: PropTypes.string.isRequired,
	clearSearchTermOnLeave: PropTypes.bool.isRequired,
};

PageSearchForm.defaultProps = {
	placeholder: '',
	context: '',
	clearSearchTermOnLeave: false,
};
