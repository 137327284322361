import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { __ } from '@wordpress/i18n';
import { SkipNavLink } from 'components/Accessibility/SkipNav';
import Logo from 'components/Layout/Header/Logo';
import Menu from 'components/Navigation/Menu';
import useToggle from 'hooks/useToggle';
import { getFooterLinks, legalLinksLinkItems } from 'utils/links-transformers';
import { getGlobalLinks } from 'components/Global';

export default function DesktopSiteHeader({ menus, menuItems, logo, epConfig }) {
	const [isOpen, toggle] = useToggle();
	const [footerLinkInfo, setFooterLinkInfo] = useState(getFooterLinks());
	const { domain: homeUrl = '/' } = menus?.currentSite || {};

	useEffect(() => {
		let _footerLinkInfo = getFooterLinks();

		if (Number(process.env.NEXT_PUBLIC_FOOTER_SB_ONLY) === 1) {
			_footerLinkInfo = {
				..._footerLinkInfo,
				general: [getGlobalLinks().starbucksMainSite],
			};
		}

		setFooterLinkInfo(_footerLinkInfo);
	}, []);

	return (
		<div className="sb-container--fluid sb-site-header__container sb-site-header__container--desktop">
			<SkipNavLink className="visually-hidden-focusable skip-link" id="primary">
				{__('Skip to content', 'starbucks')}
			</SkipNavLink>

			{logo && <Logo href={homeUrl} filepath={logo} />}

			<Menu
				desktop
				menus={menus}
				menu={menuItems}
				active={isOpen}
				onToggle={toggle}
				epConfig={epConfig}
				legalLinks={legalLinksLinkItems(footerLinkInfo.legal)}
			/>
		</div>
	);
}

DesktopSiteHeader.propTypes = {
	menus: PropTypes.shape({
		primary: PropTypes.arrayOf(PropTypes.object),
		region: PropTypes.arrayOf(PropTypes.object),
		currentSite: PropTypes.object,
	}),
	menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};
