import { kebabCase } from 'utils/string';

/**
 * @todo document this function
 * @param {*} posts
 * @param {*} postType
 */
export const filterByPostType = (posts, postType) => {
	return posts.filter((post) => {
		return post.type === postType;
	});
};

/**
 * @todo document this function
 * @param {*} posts
 */
export const sortByDateAsc = (posts) => {
	return posts.sort((a, b) => {
		return new Date(a.date).getTime() > new Date(b.date).getTime();
	});
};

/**
 * @todo document this function
 * @param {*} posts
 */
export const sortByDateDesc = (posts) => {
	return sortByDateAsc(posts).reverse();
};

/**
 * Returns the text from a HTML string
 *
 * @param {html} String The html string
 */
export const stripHtmlFromString = (html) => {
	let output = html;

	if (html) {
		output = html.replace(/(<([^>]+)>)/gi, '');
	}

	return output;
};

/**
 * Checks wether the provided content contains a video embed.
 *
 * @param {string} content WordPress `content.rendered` string
 * @returns {boolean} wether or not the post content has a video embed inside it
 */
export const hasVideo = (content) => {
	const hasVideoEmbed = typeof content === 'string' ? content?.includes('is-type-video') : false;

	return hasVideoEmbed;
};

/**
 * Generate classname for color.
 *
 * @param {string} colorContextName background, etc.
 * @param {string} colorSlug Color slug
 * @returns {string}
 */
export function getColorClassName(colorContextName, colorSlug) {
	if (!colorContextName || !colorSlug) {
		return undefined;
	}

	return `has-${kebabCase(colorSlug)}-${colorContextName}`;
}
