import * as React from 'react';
import { getPercentRead } from 'utils/scroll';

export const ScrollContext = React.createContext();

export const ScrollContextProvider = ({ children }) => {
	const [percentScroll, setPercentScroll] = React.useState(0);

	const handleScroll = () => {
		setPercentScroll(getPercentRead('content-wrap'));
	};

	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return <ScrollContext.Provider value={percentScroll}>{children}</ScrollContext.Provider>;
};
