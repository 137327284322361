import { getDomainFromLink, toSlug } from 'utils/url';
import { getDomainByLocale } from 'lib/i18n/utils';

const SLUG_MAP = {
	press: { from: '/press', to: '/press' },
	story: { from: '/stories', to: '/stories' },
	story_category_view: { from: '/stories', to: '/stories' },
	leadership: { from: '/leadership', to: '/leadership' },
	multimedia: { from: '/multimedia', to: '/multimedia' },
	productions: { from: '/productions', to: '/productions' },
};

export const transformLink = (href, type, locale = '', isInBodyLink = false) => {
	if (isInBodyLink) {
		const hrefDomain = getDomainFromLink(href);
		const localeDomain = getDomainByLocale(locale);

		if (hrefDomain !== null && typeof localeDomain !== 'undefined') {
			if (localeDomain?.domain !== hrefDomain) {
				return href;
			}
		}
	}

	let link = toSlug(href);
	const post = SLUG_MAP?.[type] ?? {};

	if (locale === 'en-ca') {
		link = link.replace('/en/', '/en-ca/');
	}

	return link.replace(post.from, post.to);
};
