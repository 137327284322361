const query = {
	sort: [
		{
			_score: {
				order: 'desc',
			},
		},
	],
	query: {
		function_score: {
			query: {
				bool: {
					should: [
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.category.name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.category.name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.category.name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'post',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'page',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'press',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.post_tag.name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'story',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'leadership',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'category_view',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'multimedia_type_view',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'original_view',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'post_tag_view',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'press_type_view',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'story_category_view',
										},
									},
								],
							},
						},
						{
							bool: {
								must: [
									{
										bool: {
											should: [
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														type: 'phrase',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 4,
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'terms.ep_custom_result.name^9999',
														],
														boost: 2,
														fuzziness: 0,
														operator: 'and',
													},
												},
												{
													multi_match: {
														query: '%SEARCH_TERMS%',
														fields: [
															'post_title^1',
															'post_excerpt^1',
															'post_content^1',
															'post_author.display_name^1',
															'post_title.suggest^1',
														],
														fuzziness: 'auto',
													},
												},
											],
										},
									},
								],
								filter: [
									{
										match: {
											'post_type.raw': 'visibility_view',
										},
									},
								],
							},
						},
					],
				},
			},
			functions: [
				{
					exp: {
						post_date_gmt: {
							scale: '14d',
							decay: 0.25,
							offset: '7d',
						},
					},
				},
			],
			score_mode: 'avg',
			boost_mode: 'sum',
		},
	},
	post_filter: {
		bool: {
			must: [
				{
					terms: {
						post_status: ['publish'],
					},
				},
				{
					terms: {
						'post_type.raw': ['press', 'story'],
					},
				},
			],
		},
	},
};

export default query;
